import React from "react";

const Divider = ({ text }) => {
  return (
    <div className="common-divider">
      <p className="divider__text">{text}</p>
      <div className="divider__text--hr"></div>
    </div>
  );
};

export default Divider;
