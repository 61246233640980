import React from "react";
import CommonSectionHeader from "../../components/Shared/CommonSectionHeader";
import CommonButton from "../../components/Shared/CommonButton";
import Divider from "../../components/Shared/Divider";

const ContactUs = () => {
  return (
    <div id="contactUs" className="contactUs-section">
      <div className="common-container">
        <CommonSectionHeader text="Contact Us" />
        <div className="contactUs-body">
          <div className="contactUs__formArea">
            <h1 className="contactUs__formArea--heading">Ask Your Queries</h1>
            <form className="contactUs__form">
              <input
                type="text"
                className="contactUs__form--input"
                placeholder="Name*"
                name=""
              />
              <input
                type="text"
                className="contactUs__form--input"
                placeholder="Email*"
                name=""
              />
              <textarea
                className="contactUs__form--input"
                name=""
                style={{ height: "90px" }}
                defaultValue="Message"
              ></textarea>
              <CommonButton text="SEND" />
            </form>
          </div>

          <div className="contactUs__textArea">
            <h2 className="contactUs__textArea--text contactUs__textArea--header">
              Ask Your Queries
            </h2>
            <h2 className="contactUs__textArea--text">
              9810606691, 8851976279.
            </h2>
            <h2 className="contactUs__textArea--text">
              Info@srisrisahajevents.com
            </h2>
            <h2 className="contactUs__textArea--text">
              Srisrisahajevents.com.
            </h2>
            <div className="contactUs__textArea__divider">
              <Divider text="Or" />
            </div>
            <a href="tel:%20+919810606691">
              <CommonButton text="CALL US" iconShow={true} />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
