import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const CommonSlider = ({ items }) => {
  const settings = {
    slidesToShow: 3,
    slidesToScroll: 1,
    // autoplay: true,
    // autoplaySpeed: 1000,
    // speed: 3000,
    // cssEase: "ease",
    dots: true,
    responsive: [
      {
        breakpoint: 1024, // Medium devices and above
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768, // Small devices
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="commonSlider-section">
      <Slider {...settings} className="commonSlider__body">
        {items.map((img, index) => (
          <div key={index} className="commonSlider__item">
            <img src={img} alt="" className="commonSlider__item--img" />
            <div className="commonSlider__item--overlay"></div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default CommonSlider;
