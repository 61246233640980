import React, { useState } from "react";
import { Link as ScrollLink } from "react-scroll";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={`navbar-section`}>
      <div
        className="navbar-container common-container"
        style={{ padding: "18px 0" }}
      >
        <div className="navbar__logo">
          <ScrollLink
            to="header"
            spy={true}
            smooth={true}
            offset={0}
            duration={500}
          >
            <img
              src="https://srisrisahajevents.com/wp-content/uploads/2023/04/cropped-Black_White_Minimalist_Wedding_Monogram_Logo-removebg-preview-1-e1681238084168-160x150.png"
              alt=""
              className="navbar__logo--img"
            />
          </ScrollLink>
        </div>

        <div className="navbar-right">
          <div className="large-device-menu" style={{ color: "#fff" }}>
            <ScrollLink
              to="aboutUs"
              spy={true}
              smooth={true}
              offset={0}
              duration={500}
            >
              About Us
            </ScrollLink>
            <ScrollLink
              to="services"
              spy={true}
              smooth={true}
              offset={0}
              duration={500}
            >
              Services
            </ScrollLink>
            <ScrollLink
              to="gallery"
              spy={true}
              smooth={true}
              offset={0}
              duration={500}
            >
              Gallery
            </ScrollLink>
            <ScrollLink
              to="contactUs"
              spy={true}
              smooth={true}
              offset={-50}
              duration={500}
            >
              Contact Us
            </ScrollLink>
          </div>

          <div className="small-device-menu">
            <div className="menu-bar">
              <svg
                onClick={() => setIsOpen(true)}
                className="small-device__sidebar-toggle"
                focusable="false"
                aria-hidden="true"
                viewBox="0 0 24 24"
                data-testid="MenuIcon"
                style={{ color: "#fff" }}
              >
                <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"></path>
              </svg>
            </div>
            <div className={`sidebar-wrapper ${isOpen ? "open" : ""}`}>
              <div className={`sidebar ${isOpen ? "" : ""} `}>
                <div className="sidebar__body">
                  <li className="body__menu">
                    <ScrollLink
                      to="aboutUs"
                      spy={true}
                      smooth={true}
                      offset={0}
                      duration={500}
                      onClick={() => setIsOpen(false)}
                    >
                      About Us
                    </ScrollLink>
                  </li>
                  <li className="body__menu">
                    <ScrollLink
                      to="services"
                      spy={true}
                      smooth={true}
                      offset={0}
                      duration={500}
                      onClick={() => setIsOpen(false)}
                    >
                      Services
                    </ScrollLink>
                  </li>
                  <li className="body__menu">
                    <ScrollLink
                      to="gallery"
                      spy={true}
                      smooth={true}
                      offset={0}
                      duration={500}
                      onClick={() => setIsOpen(false)}
                    >
                      Gallery
                    </ScrollLink>
                  </li>
                  <li className="body__menu">
                    <ScrollLink
                      to="contactUs"
                      spy={true}
                      smooth={true}
                      offset={-50}
                      duration={500}
                      onClick={() => setIsOpen(false)}
                    >
                      Contact Us
                    </ScrollLink>
                  </li>
                </div>
              </div>
              <div
                className="sidebar__backdrop"
                onClick={() => setIsOpen(false)}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
