import React from "react";
import CommonSectionHeader from "../../components/Shared/CommonSectionHeader";

const OurServices = () => {
  return (
    <div id="services" className="ourServices-section">
      <div className="common-container">
        <CommonSectionHeader text="Our Services" />
        <div className="ourServices-body">
          <div
            className="ourServices__card"
            style={{
              backgroundImage: `url("https://srisrisahajevents.com/wp-content/uploads/2023/04/Red-scaled.jpg")`,
            }}
          >
            <h4 className="ourServices__card--header">DECOR</h4>
            <p className="ourServices__card--title titleOne">
              – All wedding events
            </p>
            <p className="ourServices__card--title titleTwo">
              – Corporate events
            </p>
            <div className="ourServices__card--overlay"></div>
          </div>
          <div
            className="ourServices__card"
            style={{
              backgroundImage: `url("https://srisrisahajevents.com/wp-content/uploads/2023/04/DSC_0909-scaled.jpg")`,
            }}
          >
            <h4 className="ourServices__card--header">BUDGET MANAGEMENT</h4>
            <p className="ourServices__card--title titleOne">
              – Providing flexible
            </p>
            <p className="ourServices__card--title titleTwo">
              – Services with your budget
            </p>
            <div className="ourServices__card--overlay"></div>
          </div>
          <div
            className="ourServices__card"
            style={{
              backgroundImage: `url("https://srisrisahajevents.com/wp-content/uploads/2023/04/IMG_7119-scaled.jpg")`,
            }}
          >
            <h4 className="ourServices__card--header">ENTERTAINMENT</h4>
            <p className="ourServices__card--title titleOne">
              – Celebrity Management
            </p>
            <p className="ourServices__card--title titleTwo">
              – Dance Choreography
            </p>
            <p className="ourServices__card--title titleThree">
              – All type of bands
            </p>
            <div className="ourServices__card--overlay"></div>
          </div>
          <div
            className="ourServices__card"
            style={{
              backgroundImage: `url("https://srisrisahajevents.com/wp-content/uploads/2023/04/Green-scaled.jpg")`,
            }}
          >
            <h4 className="ourServices__card--header">DESTINATION & VENUES</h4>
            <p className="ourServices__card--title titleOne">– Logistics</p>
            <p className="ourServices__card--title titleTwo">– Management</p>
            <div className="ourServices__card--overlay"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurServices;
