import React from "react";

const CommonSectionHeader = ({ text }) => {
  return (
    <div className="commonSectionHeader">
      <h2 className="commonSectionHeader--text">{text}</h2>
    </div>
  );
};

export default CommonSectionHeader;
