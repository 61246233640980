/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import Navbar from "../../containers/Shared/Navbar";
import CommonButton from "../../components/Shared/CommonButton";
import { Link as ScrollLink } from "react-scroll";

const Header = () => {
  const [headerBg, setHeaderBg] = useState(
    "https://srisrisahajevents.com/wp-content/uploads/2023/04/decor-046-scaled.jpg"
  );

  const images = [
    "https://srisrisahajevents.com/wp-content/uploads/2023/04/decor-047-scaled.jpg",
    "https://srisrisahajevents.com/wp-content/uploads/2023/04/decor-046-scaled.jpg",
    "https://srisrisahajevents.com/wp-content/uploads/2023/04/decor-048-scaled.jpg",
    "https://srisrisahajevents.com/wp-content/uploads/2023/04/decor-049-scaled.jpg",
    "https://srisrisahajevents.com/wp-content/uploads/2023/04/decor-051-scaled.jpg",
  ];

  useEffect(() => {
    const intervalId = setInterval(() => {
      // Rotate through images
      const newIndex = (images.indexOf(headerBg) + 1) % images.length;
      setHeaderBg(images[newIndex]);
    }, 3000);

    // Cleanup the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, [headerBg, images]);

  return (
    <div
      id="header"
      className="header-section"
      style={{
        backgroundImage: `url(${headerBg})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundAttachment: "fixed",
      }}
    >
      <Navbar />
      <div className="section-layer"></div>
      <h1 className="header__title">SAHAJ EVENTS</h1>
      <p className="header__paragraph">Make it memorable!</p>
      <div className="header__button">
        <ScrollLink
          to="contactUs"
          spy={true}
          smooth={true}
          offset={0}
          duration={500}
        >
          <CommonButton text="MAKE AN ENQUIRY" iconShow={true} />
        </ScrollLink>
      </div>
    </div>
  );
};

export default Header;
