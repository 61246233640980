import React from "react";
import Header from "./Header";
import AboutUs from "./AboutUs";
import OurServices from "./OurServices";
import BackgroundOverlaySection from "./BackgroundOverlaySection";
import Gallery from "./Gallery";
import OurClients from "./OurClients";
import Footer from "../../containers/Shared/Footer";
import ContactUs from "./ContactUs";

const HomePage = () => {
  return (
    <div>
      <Header />
      <AboutUs />
      <OurServices />
      <BackgroundOverlaySection />
      <Gallery />
      <OurClients />
      <ContactUs />
      <Footer />
    </div>
  );
};

export default HomePage;
