import React from "react";
import { FaArrowRightLong } from "react-icons/fa6";

const CommonButton = ({ text, iconShow }) => {
  return (
    <button className="commonButton">
      <span className="commonButton--text">{text}</span>
      {iconShow && <FaArrowRightLong className="commonButton--icon" />}
    </button>
  );
};

export default CommonButton;
