import React from "react";
import CommonSectionHeader from "../../components/Shared/CommonSectionHeader";
import CommonSlider from "../../components/Shared/CommonSlider";

const Gallery = () => {
  const items = [
    "https://srisrisahajevents.com/wp-content/uploads/elementor/thumbs/20230204_184845-scaled-qfhcl54exkbyw0z6itw615378ht5xml5vcalrlo3go.jpg",
    "https://srisrisahajevents.com/wp-content/uploads/elementor/thumbs/IMG-20221218-WA0266-qfhcohfr8cwo644f09wewaq79tc5avuszvsr70ppc8.jpg",
    "https://srisrisahajevents.com/wp-content/uploads/elementor/thumbs/IMG-20221218-WA0259-qfhcoem8nust7a8igqoj6tfthnq1nsjlzhuar6tvuw.jpg",
  ];

  return (
    <div id="gallery" className="gallery-section">
      <div className="common-container">
        <CommonSectionHeader text="Gallery" />
        <p className="gallery__top--paragraph">Peek Into Our World</p>
        <div className="gallery__body">
          <CommonSlider items={items} />
        </div>
      </div>
    </div>
  );
};

export default Gallery;
